import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Landing_page from "./componets/main/landing_page";
import { AuthProvider } from './componets/main/AuthContext'; // Check correct path
import {NavbarSimple} from "./componets/common/nav-bar";
import SearchResults from "./componets/main/search results";
import Login from './componets/common/google-loginbutton'
import Mainpage from "./componets/main/mainpage";
import PrivacyPolicy from "./componets/main/priavciy";
import TermsOfService from "./componets/main/terms";
function App() {
    return (
        <AuthProvider> {/* Use AuthProvider here */}
            <Router>
                <div className="App">
                    <div>
                    <Login/>
                    </div>
                    <Routes>
                        <Route path='/' element={<Mainpage />} />
                        <Route path='/tos' element={<Landing_page />} />
                        <Route path='/results' element={<SearchResults />} />
                        <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
                        <Route path='/termsofservice' element={<TermsOfService/>}/>
                    </Routes>
                </div>
            </Router>
        </AuthProvider>
    );
}

export default App;


