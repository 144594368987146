import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../main/AuthContext';
import './results.css';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

function Landing() {
    const { user, isAuthenticated } = useAuth();
    const [liked,setliked] = useState(true)
    const [searchResults, setSearchResults] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const location = useLocation();
    const [searchInput, setSearchInput] = useState('');
    const [showContent, setShowContent] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState(new Set());
    console.log('Resultpage',isAuthenticated)
    const toggleContent = () => setShowContent(!showContent);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const encodedQuery = queryParams.get('q');
        const decodedQuery = decodeURIComponent(encodedQuery);

        // Set the search input to the decoded query for display purposes
        setSearchInput(decodedQuery);

        const fetchData = async () => {
            try {
                // Use the encoded query for the API call
                const response = await axios.get(`https://floating-lake-46912-b5b89c67b62e.herokuapp.com/search?q=${encodedQuery}`);
                setSearchResults(response.data);
            } catch (error) {
                console.log('ERROR', error);
            }
        };

        fetchData();
    }, [location.search]);
    const createMarkup = (markdownText) => {
        const adjustedText = markdownText.replace(/\n\n/g, '\n');
        const rawMarkup = marked(adjustedText);
        const cleanMarkup = DOMPurify.sanitize(rawMarkup);
        return { __html: cleanMarkup };
    };

    const selectVideo = (post) => {
        if (post.platform === 'YouTube') {
            setSelectedVideo(post);
        }
    };

    const handleFilterChange = (event) => {
        const platform = event.target.value;
        setSelectedFilters(prevFilters => {
            const newFilters = new Set(prevFilters);
            if (event.target.checked) {
                newFilters.add(platform);
            } else {
                newFilters.delete(platform);
            }
            return newFilters;
        });
    };
    const [filteredResults, setFilteredResults] = useState([]);

    useEffect(() => {
        const newFilteredResults = searchResults.filter(result =>
            selectedFilters.size === 0 || selectedFilters.has(result.platform)
        );
        setFilteredResults(newFilteredResults);
    }, [searchResults, selectedFilters]);
    const incrementLabel = async (postId) => {
        try {
            if (isAuthenticated) {console.log('Sending request with itemId:', { itemId: postId });

                // Note: No need to manually set the authorization header here
                // as authentication is session-based

                const config = {
                    withCredentials: true // Important for sending session cookies
                };

                const response = axios.post('https://floating-lake-46912-b5b89c67b62e.herokuapp.com/api/like', { itemId: postId }, { withCredentials: true });

                if (response.status === 200) {
                    setSearchResults(currentResults =>
                        currentResults.map(post =>
                            (post.red_id === postId || post.videoId === postId)
                                ? { ...post, label: post.label + 1 }
                                : post
                        )
                    );
                }
            } else {
                alert('Please log in to like posts');
            }
        } catch (error) {
            console.error('Error liking the item:', error);
            if (error.response && error.response.status === 401) {
                alert('Session expired, please log in again');
            }
        }
    };



    const getEmbedUrl = (videoId) => `https://www.youtube.com/embed/${videoId}`;
    return (
        <div className='results-page'>
            <div className="wrapper">
                <div className='ttp'>
                    {/* Additional components */}
                    <h1>Remix</h1>
                    <div className='search-bar'>
                        <i className="fa-solid fa-magnifying-glass"></i>
                        <input
                            type="text"
                            value={searchInput} // Make sure this is bound to the state that holds the decoded query
                            onChange={e => setSearchInput(e.target.value)}
                            placeholder="Search here"
                        />
                    </div>
                    <i className="fa-solid fills fa-filter" onClick={toggleContent}></i>
                    {showContent && (
                        <div className='fill-card'>
                            <h1>Sources</h1>
                            <label>
                                <p>reddit</p>
                                <input
                                    type="checkbox"
                                    value = 'reddit'
                                    onChange={handleFilterChange}
                                />
                            </label>
                            <label>
                                <p>YouTube</p>
                                <input
                                    type="checkbox"
                                    value= 'YouTube'
                                    onChange={handleFilterChange}
                                />
                            </label>
                            <label>
                               <p>
                                   Dailymotionp
                               </p>
                                <input type="checkbox"
                                value='Dailymotion'
                                onChange={handleFilterChange}
                                />

                            </label>
                        </div>
                    )}
                </div>

                <div className='landing-main'>
                    {/* Container for Reddit posts */}
                    <div className='reddit-container'>
                        {Array.isArray(filteredResults) && filteredResults.map((post, index) => {
                            if (post.platform === 'reddit') {
                                return (
                                    <div key={index} className='reddit-card' onClick={() => selectVideo(post)}>
                                        <div className='top-title'>
                                        <a href={post.url}>{post.title}</a>
                                        <span>{" posted by u/" + post.author + " on " + new Date(post.date * 1000).toUTCString()}</span>
                                        </div>
                                        <div className='reddit-cont'>
                                                <div className='mark' dangerouslySetInnerHTML={createMarkup(post.richtext)} />
                                                <img src={post.imgs} alt="post" />
                                        </div>
                                        {isAuthenticated && (
                                            <button onClick={() => incrementLabel(post.red_id || post.videoId)}>
                                                <i className="fa-regular fa-thumbs-up"></i> Like
                                            </button>
                                        )}
                                        <p>Likes: {post.label}</p>
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>

                    {/* Container for other platform posts (videos) */}
                    <div className='video-containers'>
                        {Array.isArray(filteredResults) && filteredResults.map((post, index) => {
                            if (post.platform !== 'reddit') {
                                return (
                                    <div key={index} className='card' onClick={() => selectVideo(post)}>
                                        <img src={post.imgs} alt={post.title} />
                                        <p className='text-overlay'>{post.views}</p>
                                    </div>

                            );
                            }
                            return null;
                        })}
                    </div>
                </div>
            </div>

            {/* Modal for selected video */}
            {selectedVideo && selectedVideo.platform === 'YouTube' && (
                <div className="video-modal" onClick={() => setSelectedVideo(null)}>
                    <div className="video-container" onClick={e => e.stopPropagation()}>
                        <iframe
                            width="560"
                            height="315"
                            src={getEmbedUrl(selectedVideo.videoId)}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                        </iframe>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Landing;