import React from 'react';
import '../main/policy.css'
function PrivacyPolicy() {
    return (
        <div className='wrapperr'>
        <div className='policy'>
            <h1>Privacy Policy for Remixs</h1>
            <p>Effective Date: 2023</p>

            <p>
                Remixs operates the  website
            </p>

            <p>
                This page informs you of our policies regarding the collection, use, and disclosure
                of personal data when you use our Service.
            </p>

            <h1>1. Information Collection and Use</h1>
            <p>
                We collect limited personal information from our users to enhance their experience with our Service.
            </p>

            <h2>Types of Data Collected</h2>
            <p><strong>Personal Data</strong>: We collect your email address, username, and profile picture from
                your Google account when you use our Service. We do not collect any other personal information.
            </p>

            <h1>2. Use of Data</h1>
            <p>
                Your personal information is used for the following purposes:
                <ul>
                    <li>To provide and maintain our Service</li>
                    <li>To personalize your experience</li>
                    <li>To communicate with you</li>
                    <li>To provide customer support</li>
                    <li>To detect, prevent, and address technical issues</li>
                </ul>
            </p>

            <h1>3. Security of Data</h1>
            <p>
                We prioritize the security of your data. While no method of transmission over the Internet
                or electronic storage is 100% secure, we strive to use commercially acceptable means to protect your Personal Data.
            </p>

            <h1>4. Disclosure of Data</h1>
            <p>
                We do not share, sell, or disclose your Personal Data with third parties except in the following cases:
                <ul>
                    <li>To comply with legal obligations</li>
                    <li>To protect and defend our rights or property</li>
                    <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                    <li>To protect the personal safety of users of the Service or the public</li>
                    <li>To protect against legal liability</li>
                </ul>
            </p>

            <h1>5. Changes to This Privacy Policy</h1>
            <p>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting
                the new Privacy Policy on our website.
            </p>

            <h1>6. Contact Us</h1>
            <p>
                If you have any questions about this Privacy Policy, please contact us:
                <ul>
                    <li>By email: help@remixs.net</li>
                </ul>
            </p>
        </div>
        </div>
    );
}

export default PrivacyPolicy;
