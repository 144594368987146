import React from 'react';

function TermsOfService() {
    return (
        <div className='wrapperr'>
            <div className='policy'>
            <h1>Terms of Service for Remixs</h1>
            <p>Last updated: 2023</p>

            <p>
                Welcome to Remixs! Our service aggregates content from various social media platforms
                and presents it for easy viewing. Please read these Terms of Service ("Terms", "Terms of Service")
                carefully before using the Remixs website  operated by Remixs.
            </p>

            <h1>1. Acceptance of Terms</h1>
            <p>
                By accessing or using the Service, you agree to be bound by these Terms. If you disagree with
                any part of the terms, then you may not access the Service.
            </p>

            <h1>2. Changes to Terms</h1>
            <p>
                We reserve the right, at our sole discretion, to modify or replace these Terms at any time.
                We will provide notice prior to any new terms taking effect.
            </p>

            <h1>3. Use of Service</h1>
            <p>
                Our Service aggregates content from various social media platforms. You understand that we do
                not control, and are not responsible for, content made available through these platforms.
            </p>

            <h1>4. Intellectual Property</h1>
            <p>
                The Service and its original content (excluding Content provided by social media platforms),
                features, and functionality are and will remain the exclusive property of Remixs
                and its licensors.
            </p>

            <h1>5. Termination</h1>
            <p>
                We may terminate or suspend access to our Service immediately, without prior notice or liability,
                for any reason whatsoever, including, without limitation, if you breach the Terms.
            </p>

            <h1>6. Disclaimer</h1>
            <p>
                Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE"
                basis. The Service is provided without warranties of any kind.
            </p>

            <h1>7. Contact Us</h1>
            <p>
                If you have any questions about these Terms, please contact us at help@remixs.net
            </p>
        </div>
        </div>
    );
}

export default TermsOfService;
