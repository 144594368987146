import React from 'react';
import axios from 'axios';
import { useAuth } from '../main/AuthContext';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import '../main/google-but.css';

function GoogleLoginButton() {
    const { setIsAuthenticated, isAuthenticated, setUser , user} = useAuth();
    const navigate = useNavigate();

    const handleGoogleSignIn = async (credentialResponse) => {
        try {
            const response = await axios.post('https://floating-lake-46912-b5b89c67b62e.herokuapp.com/auth/google/token', {
                token: credentialResponse.credential,
            });
            const userData = response.data.user
            if (response.status === 200 && response.data.isAuthenticated) {
                setIsAuthenticated(true);
                setUser(userData);
                console.log(user)
                navigate('/'); // Redirect to the home page or another route as needed
            }
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    const handleLogout = async () => {
        try {
            await axios.get('https://floating-lake-46912-b5b89c67b62e.herokuapp.com/auth/logout');
            setIsAuthenticated(false);
            setUser(null);
            navigate('/'); // Redirect to the home page or another route as needed
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <div className='google'>
            {!isAuthenticated ? (
                <GoogleLogin
                    onSuccess={handleGoogleSignIn}
                    onError={() => console.log("Login Failed")}
                />
            ) : (
                <button onClick={handleLogout}>
                    Logout
                </button>
            )}
            {isAuthenticated && user && (
                <div className='user-data'>
                    <h1>Welcome {user.username}</h1>
                    <img src={user.profilePicture} alt="User Profile" />
                </div>
            )}
        </div>
    );
}

export default GoogleLoginButton;

