import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
axios.defaults.withCredentials = true;

const AuthContext = createContext({
    isAuthenticated: false,
    user: null,
    setIsAuthenticated: () => {}
});
export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const getUser = async () => {
            try {
                const response = await axios.get('https://floating-lake-46912-b5b89c67b62e.herokuapp.com/api/current_user', { withCredentials: true });
                if (response.status === 200 && response.data.isAuthenticated) {
                    setIsAuthenticated(true);
                    setUser(response.data.user);
                } else {
                    setIsAuthenticated(false);
                    setUser(null);
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
                setIsAuthenticated(false);
                setUser(null);
            }
        };

        getUser();
    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, user,setUser, setIsAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
